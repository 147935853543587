.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

header {
  z-index: 99999;
  position: relative;
}

#about-section {
  background-image: url("../public/images/hero_1_no-text.jpg");
}

/* .donate{
  background-image: url('../public/img/carousel-2.jpg');
} */
.mw-500 {
  max-width: 500px;
}

.mw-400 {
  max-width: 400px;
}

.mh-450 {
  min-height: 450px;
}

.mh-400 {
  min-height: 400px;
}

.h-60 {
  height: 60px;
}

.w-200 {
  width: 200px;
}

.h-200 {
  height: 200px;
}

.of-cover {
  object-fit: cover;
}

.l-0 {
  left: 0;
}

.h-100 {
  height: 100px;
}

@media (min-width: 250px) {
  .custom-height {
    width: 400px;
  }

  .custom-width {
    width: 300px;
  }
}

@media (min-width: 576px) {
  .custom-height {
    height: 300px;
  }

  .custom-width {
    width: 500px;
  }
}

.margin-learn {
  margin-top: 120px;
}

.object-cover {
  object-fit: cover;
  /* This ensures that the image covers the entire container */
  width: 100%;
  /* Ensures the image takes up the full width of its container */
  height: 100%;
  /* Ensures the image takes up the full height of its container */
}

.hover-class:hover {
  background-color: white;
  transition-duration: 300;
}

.ac-details {
  background-color: #001d23;
}
